import { inject, autoinject } from 'aurelia-framework';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { BindingEngine } from 'aurelia-binding'


import { Store, connectTo } from "aurelia-store";
import { State } from "state";
@connectTo()
@autoinject()
@inject(ValidationControllerFactory, BindingEngine)

export class Test {
  private state: State;
  wallets: IGenericAccount[] = [];
  
  controller = null;
  inputValue = '';
  bindingEngine: BindingEngine = null;

  private sanitizedValue = "";
  private sanitizedName = "";
  private name_wallet: any = "";
  private paymail_wallet: string = "";

  constructor(
    controllerFactory,
    bindingEngine
  ) {
    this.controller = controllerFactory.createForCurrentScope();
    this.inputValue = '';
    this.bindingEngine = bindingEngine;
    this.bindingEngine.propertyObserver(this, 'inputValue').subscribe(() => this.inputValueUpdated());
  }

  inputValueUpdated() {
    this.controller.validate();
  }

  copy() {
    navigator.clipboard.writeText(this.state.wallets[this.state.selectedAccountIndex].identification);
    
  }

  sanitizeName(newValue: string) {
    const lowercasedValue = newValue;
    this.sanitizedName = lowercasedValue.replace(/[^a-zA-Z0-9_ ]/g, "_");
    this.name_wallet = this.sanitizedName;
  }

  sanitizedValueChanged(newValue: string) {
    const lowercasedValue = newValue.toLowerCase();
    this.sanitizedValue = lowercasedValue.replace(/[^a-z0-9_]/g, "_");
    this.paymail_wallet = this.sanitizedValue;
  }

}

ValidationRules
  .ensure('inputValue')
  .minLength(3).withMessage('Input must have at least 3 characterz')
  .satisfies(value => /^[a-z0-9]+$/i.test(value)).withMessage('Input must not contain special characters')
  .satisfies(value => value === value.toLowerCase()).withMessage('Input must be lowercase')
  .maxLength(31).withMessage('Input must have less than 31 characterz')
  .on(Test);
