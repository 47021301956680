// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./test.scss"></require>
  <require from="../../components/loader-ring/loader-ring"></require>
  <require from="../../components/loading/loading"></require>
  <require from="../../styles.scss"></require>

  <require from="../../components/header-titles/header-title-home/header-title-home"></require>
  <require from="../../components/ValueConverters/currency-denomination"></require>
  <require from="../../components/number-format-eur"></require>
  <require from="../../components/number-format-bsv"></require>
  <require from="../../components/date-format-full-day"></require>
  <require from="../../components/mybankabrand/mybankabrand"></require>
  <require from="../../components/ValueConverters/iban-converter"></require>
  
  <div class="flex-column">
    <section id="test" class="row">
      <div class="loading-bar"></div>
      <div id="header" class="flex-row">
        <img id="thumbnail" click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_0___}">
        <h1>TEST - Components for both flows</h1>
      </div>
      <div class="leftsidelinks">
        <table class="flex-row left">
          <th>MENU</th>
          <tr class="flex-column">
            <td><a href="/test/">Welcome 2 Test</a></td>
            <!-- <td><a route-href="route:test">QL U R HERE</a></td> -->
            <!-- <td><a route-href="home" router-ignore>Home?</a></td> -->
            <td><a href="home" router-ignore>Home</a></td>
            <td><a href="choose_currency_format" router-ignore>Choose Currency Format</a></td>
          </tr>
        </table>
        <div class="vertical-loading">
          <div class="scanner"></div>
        </div>
      </div>
      <div class="flex-column" style="margin:auto;">
        <div class="flex-row" style="color: antiquewhite; margin:auto;">
          <div>
            COLOURD INPUT
          </div>
          <div class="flex-grow"></div>
          <div>
            Name Wallet
          </div>
        </div>
        <div class="flex-row" style="margin:auto;">
          <form role="form" class="inputs" style="width: 100%;">
            <div class="form-group">
              <input class="top" 
              class.bind="controller.errors.length > 0 ? 'red' : 'green'"
              type="text" 
              value.bind="inputValue & validate & updateTrigger:'input'" id="input" placeholder="Enter input" class="form-control">
              <div class="hide">
                <span repeat.for="error of controller.errors" class="help-block text-danger" >\${error.message}</span>
              </div>
            </div>
          </form>
          <form id="namewallet" class="inputs" submit.delegate="goToGenPrivateKey()">
            <!-- <p t="bitcoin.name_bitcoin_wallet.your_wallet_name"></p> -->
            <div>
              <input id="name_wallet" class="top" class.bind="shaking ? 'shake' : ''" type="name" value.bind="name_wallet" minlength="3" maxlength="31" 
                i18n="[placeholder]bitcoin.name_bitcoin_wallet.your_wallet_name_placeholder" input.delegate="sanitizeName(name_wallet)"/>
            </div>
            <!-- <p t="bitcoin.name_bitcoin_wallet.your_paymail_address"></p> -->
            <div class="flex-row">
              <input class="left" id="paymail_wallet" type="text" minlength="3" maxlength="64" input.delegate="sanitizedValueChanged(paymail_wallet)"
                value.bind="paymail_wallet" i18n="bitcoin.paymail" placeholder="Alice" input.trigger="bind()" maxlength="31"/>
              <input class="right" id="mybanka" type="string" value.bind="mybanka" readonly />
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>

</template>
`;
// Exports
export default code;